p:not(.MuiTypography-root) {
  margin: 0 0 0.5em 0;
  padding: 0;
  line-height: 1.43;

}
h1:not(.MuiTypography-root),
h2:not(.MuiTypography-root),
h3:not(.MuiTypography-root),
h4:not(.MuiTypography-root),
h5:not(.MuiTypography-root),
h6:not(.MuiTypography-root) {
  margin: 0 0 0.5em 0;
  padding: 0;
  line-height: 1.43;
}
ol:not(.MuiList-root),
ul:not(.MuiList-root) {
  margin-block-start: 0;
  padding-inline-start:13px;

}
